import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button } from "../buttons";
import PropTypes from "prop-types";


import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

import "./style.less";
import { CompareReportsButton } from "../../sod-reports/report-actions/report-actions.jsx";
import {UsersInput, SystemIdsInput, RiskIdsInput} from "../../sod-reports/report-parameters"
import { useDispatch, useSelector } from "react-redux";
import { getFieldValidationError } from "../../../utils/sodReports.js";
import PageContentBox from "../page-content-box/page-content-box.jsx";
import { compareBusinessReport, fetchReportResults, setCompareDates, setIsComapring } from "../../../reducers/reports/userBusynessLevelSlice.js";
import {reportsService} from "../../../services/reports-service.js";

const getSystems = (params, signal) => {
    return reportsService.getSystemsForSimulationSearchHelp(params, signal);
};

const CompareReportsDialogContent = ({
    users, setUsers,
    systemsExtended, setSystemsExtended,
    risks, setRisks
}) => {
    const validationErrors = useSelector(state => state.reports.userLevel.validationErrors);

    return (
        <PageContentBox className="report-parameters-box">
            <UsersInput
                ids={users}
                setIds={setUsers}
                error={getFieldValidationError(validationErrors, "usernames")}
            />

            <SystemIdsInput
                tags={systemsExtended}
                setTags={setSystemsExtended}
                getSystems={getSystems}
            />

            <RiskIdsInput
                tags={risks}
                setTags={setRisks}
            />
        </PageContentBox >
    );
}; 



const CompareReportsDialog = ({
    open,
    setOpen,
    onClose,
    ...props
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const finalTitle = intl.formatMessage({ id: "common.compare-report-dialog.title" });

    const [users, setUsers] = useState([]); 
    const [systemsExtended, setSystemsExtended] = useState([]);
    const [risks, setRisks] = useState([]);

    const handleCompareReportsClick = async () => {
        const systems = systemsExtended.filter(item => !item.isGroup)
        const groups = systemsExtended.filter(item => item.isGroup)

        dispatch(setIsComapring(true))
        dispatch(setCompareDates(null))

        dispatch(compareBusinessReport({users: users, systems: systems, groups: groups, risks: risks}));
        dispatch(fetchReportResults());
        setOpen(false);
    };

    return (
        <CommonDialog open={!!open} className="erros-dialog" {...props}>
            <CommonDialogTitle
                title={finalTitle}
                onClose={onClose}
            />

            <CommonDialogContent>
                <CompareReportsDialogContent 
                    users={users}
                    setUsers={setUsers}
                    systemsExtended={systemsExtended}
                    setSystemsExtended={setSystemsExtended}
                    risks={risks}
                    setRisks={setRisks}
                />
            </CommonDialogContent>

            <CommonDialogActions>
                <CompareReportsButton onClick={handleCompareReportsClick}/>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="common.btn-cancel" />
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};


CompareReportsDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onClose: PropTypes.func
}

CompareReportsDialogContent.propTypes = {
    users: PropTypes.array,
    setUsers: PropTypes.func,
    systemsExtended: PropTypes.array,
    setSystemsExtended: PropTypes.func,
    risks: PropTypes.array,
    setRisks: PropTypes.func
}

export default CompareReportsDialog;
