import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { setInitialCurrentProfile, clearCurrentProfile, deleteProfile } from "../../../../reducers/profileSlice";
import { createProfile, editProfile, fetchProfileDetailed, setSubmit, fetchSapSystems } from "../../../../reducers/profileSlice";
import { setError } from "../../../../reducers/profileSlice";

import { DeleteDialog, ProgressDialog } from "../../../common/dialogs";
import { ErrorsDialog, SubmitDialog, UserLogDialog } from "../../../common/dialogs";
import { ADD_MODE, EDIT_MODE, getCurrentMode } from "../../../common/entity-form";
import { PageContentBox } from "../../../common/page-content-box";
import useLogs from "../../../common/hooks/useLogs";

import ProfileFormPageHeader from "./profile-form-page-header.jsx";
import ProfileFormPageProfile from "./profile-form-page-profile.jsx";

import "./style.less";

const ProfileFormPage = () => {
    const { name: encodedName, system } = useParams();
    const name = encodedName ? decodeURIComponent(encodedName) : undefined;

    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const busy = useSelector(state => state.profiles.busy);
    const busyType = useSelector(state => state.profiles.busyType);
    const error = useSelector(state => state.profiles.error);
    const submit = useSelector(state => state.profiles.submit);
    const profile = useSelector(state => state.profiles.currentProfile);
    const profileEditable = useSelector(state => state.profiles.currentProfileEditable);
    const profileDeleted = useSelector(state => state.profiles.currentProfileDeleted);
    const validationErrors = useSelector(state => state.profiles.validationErrors);
    const systems = useSelector(state => state.profiles.systems);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [form, setForm] = useState(null);

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("critical_profile", `${system},${name}`)

    const mode = getCurrentMode(!name, profileEditable);

    const [formErrors, setFormErrors] = useState(null)

    useEffect(() => {
        if (!validationErrors) return;

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])

    useEffect(() => {
        if (name) {
            dispatch(fetchProfileDetailed({ name, system }));
        } else {
            dispatch(setInitialCurrentProfile());
        }

        return (() => {
            dispatch(clearCurrentProfile());
        });
    }, [name, system, dispatch]);

    useEffect(() => {
        if (profile) {
            setForm({
                name: profile?.name || "",
                description: profile?.description || "",
                active: profile?.active === undefined ? true : profile?.active,
                system: profile?.system || null,
                riskLevel: profile?.riskLevel || null,
                matrices: profile?.matrices || []
            });
        }
    }, [profile, setForm]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false));

        if (mode === ADD_MODE || profileDeleted) {
            handleBackClick();
        }
    }, [submit, mode, profileDeleted, dispatch, handleBackClick]);

    const handleSaveProfile = () => {
        const formProfile = {
            name: form.name?.trim(),
            description: form.description,
            active: form.active,
            system: form.system,
            riskLevel: form.riskLevel,
            matrices: form.matrices
        };

        if (formProfile.name !== form.name) {
            setForm({
                ...form,
                name: formProfile.name
            });
        }

        if (mode === EDIT_MODE) {
            dispatch(editProfile({ profile: formProfile }));
        } else {
            dispatch(createProfile({ profile: formProfile }));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
    };

    const handleDeleteCancelClick = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteConfirmClick = () => {
        dispatch(deleteProfile(profile));
    };

    const handleEditClick = () => {
        dispatch(fetchProfileDetailed({ name, system }));
    };

    useEffect(() => {
        dispatch(fetchSapSystems({}))
    }, [])

    const handleSubmitSaveProfile = () => {
        const formProfile = {
            name: form.name,
            description: form.description,
            active: form.active,
            system: form.system,
            riskLevel: form.riskLevel,
            matrices: form.matrices
        };

        if (mode === EDIT_MODE) {
            dispatch(editProfile({ profile: formProfile, warningCheck: false }));
        } else {
            dispatch(createProfile({ profile: formProfile, warningCheck: false }));
        }
    }

    if (!profile && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <PageContentBox className="profile-form-page-profile">
                    <ProfileFormPageHeader
                        mode={mode}
                        name={name}
                        onBackClick={handleBackClick}
                        onDeleteClick={handleDeleteClick}
                        onSaveClick={handleSaveProfile}
                        onEditClick={handleEditClick}
                        onShowLogsClick={getLogs}
                    />

                    <ProfileFormPageProfile
                        form={form}
                        setForm={setForm}
                        mode={mode}
                        validationErrors={formErrors}
                        systems={systems}
                    />
                </PageContentBox>
            )}

            <DeleteDialog
                open={showDeleteDialog}
                title={intl.formatMessage({ id: "profile-form-page.delete-dialog.title" })}
                text={intl.formatMessage(
                    { id: "profile-form-page.delete-profile.text" },
                    { name: profile?.name || "" }
                )}
                onCancelClick={handleDeleteCancelClick}
                onDeleteClick={handleDeleteConfirmClick}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <SubmitDialog
                open={error && error.type === "warning" && error.code === "no_integr_objects"}
                title={error && error.title}
                message={error && error.detail}
                onCancelClick={() => { dispatch(setError(null)) }}
                buttons={[{
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-close" }),
                    onClick: () => { dispatch(setError(null)) }
                }, {
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-apply" }),
                    type: "apply",
                    onClick: () => {
                        handleSubmitSaveProfile();
                        dispatch(setError(null))
                    }
                }]}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error" || error?.type === "warning"}
                onClose={() => { dispatch(setError(null)) }}
            />

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
            />
        </>
    );
};

export default ProfileFormPage;
