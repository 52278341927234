import SodService from "./sod-service";
import { reportsModelingService } from "../services/reports-modeling-service";
import { recalcRoleTreeTrafficLightStatus } from "../utils/reports-modeling-utils"
import { nanoid } from "@reduxjs/toolkit";

class CustomRolesService extends SodService {
  async getCustomRoles(params, signal) {
    params = {
      ...params, filters: params.filters.map(item => {
        if (item.field == 'isComplexRole') {
          return {
            ...item,
            value: item.value === "True" ? 'true' : item.value === "False" ? 'false' : item.value
          };
        }
        return item;
      })
    };
    const config = this.generateConfigForGetEntities(params, signal);

    const data = await this.get("/core/custom-roles/list", config);
    const rows = data && data.rows.map(item => this.parseCustomRoles(item));
    const total = data.total

    return {
      rows, total
    }
  }

  async getCustomRolesColumns() {
    const columns = await this.getColumns("/core/custom-roles/columns");
    return columns
  }

  async getCustomRolesFilters(params) {
    return this.getFilters("/core/custom-roles/filters", params)
  }

  async getCustomRoleChangeHistory(key) {
    const data = await this.get(`/core/custom-roles/detailed/change-history/${key}`)
    return data && data.map(item => this.parseUserActionLog(item))
  }

  async deleteCustomRole(role) {
    const config = {
      params: {
        role: role.role,
        system_id: role.systemId
      }
    }

    return this.delete(`/core/custom-roles/detailed`, null, config);
  }

  async getCustomRoleDetailed(roleId, systemId) {
    const config = {
      params: {
        role: roleId,
        system_id: systemId
      }
    }
    const data = await this.get(`/core/custom-roles/detailed/`, config);

    const roleData = this.parseCustomRoleDetailed(data);

    return roleData
  }

  async createCustomRole(role, warningCheck) {
    const data = await this.post(`/core/custom-roles/detailed/`, this.parseCustomRoleToServer(role), {
      params: { warningCheck }
    });

    return this.parseCustomRoleDetailed(data);
  }

  async editCustomRole(role, warningCheck) {
    const data = await this.put(`/core/custom-roles/detailed/`, this.parseCustomRoleToServer(role), {
      params: {
        role: role.id,
        system_id: role.systemId,
        warningCheck
      }
    });

    return this.parseCustomRoleDetailed(data);
  }

  parseCustomRoles(item) {
    return {
      systemId: item.system_id,
      role: item.role,
      description: item.description,
      updatedTime: item.upd_time,
      user: item.upd_user ? item.upd_user : "",
      isComplex: item.is_complex_role,
    }
  }

  parseCustomRoleToServer(item) {
    const parsedRole = {
      role: item.id,
      system_id: item.systemId,
      system_type: item.systemType,
      description: item.description || "",
      is_complex_role: item.isComplex,
    }

    if (item.isComplex) {
      parsedRole.childrens = item.children.map(child => child.role);
    } else {
      parsedRole.menu = item.menu[0] ? item.menu[0].children?.map(child => reportsModelingService.parseRoleMenuChildVariant(child)) : []
      parsedRole.org_levels = item.orgLevelsDelta?.map(deltaItem => ({
        org_level: deltaItem.id,
        operation: deltaItem.operation,
        values: deltaItem.values?.map(({ from, to }) => ({
          value_from: from ?? "",
          value_to: to ?? ""
        }))
      }));
      parsedRole.permissions = item.delta
        .map(deltaItem => ({
          permission_object: deltaItem.permission_object,
          permission_group: deltaItem.permission,
          field: deltaItem.field,
          operation: deltaItem.operation,
          status: deltaItem.status,
          active: deltaItem.active ?? false,
          values: deltaItem.values?.map(({ from, to }) => ({
            value_from: from ?? "",
            value_to: to ?? ""
          }))
        }));
    }

    return parsedRole
  }


  parseCustomRoleDetailed(item) {
    const parsedRole = {
      role: item.role,
      systemId: item.system_id,
      systemType: item.system_type,
      description: item.description,
      isComplex: item.is_complex_role,
      children: item.is_complex_role ? item?.childrens.map(child => ({
        id: nanoid(),
        role: child.role,
        systemId: item.system_id,
        description: child.description
      })) : null,
      menu: reportsModelingService.parseRoleMenu(item.menu),
      orgLevels: reportsModelingService.parseRoleOrgLevels(item.org_levels),
      permissions: item.permissions,
      tree: reportsModelingService.parseRolePermissionsTree(item.tree),
      profile: item.profile ?? "",
      tcodeToValue: item.is_complex_role ? null : reportsModelingService.parseTcodeValues(item.tcode_values, item.system_id)
    }

    if (parsedRole.orgLevels) {
      recalcRoleTreeTrafficLightStatus(parsedRole.tree, parsedRole.orgLevels)
    }
    return parsedRole;
  }
}



const customRolesService = new CustomRolesService();

export { customRolesService };
export default CustomRolesService;