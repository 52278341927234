import React, { useState } from "react";
import GroupRoleModelingBox from "../../group-role-modeling-box";
import { nanoid } from "@reduxjs/toolkit";
import PropTypes from "prop-types";

const GroupRoleView = ({ form, setForm, disabled }) => {
  const handleAddChildRole = (newChildRole) => {
    newChildRole.id = nanoid();
    if (!form.children.some((role) => role.role === newChildRole.role)) {
      setForm({ ...form, children: [...form.children, newChildRole] });
    }
  };

  const [selectedRole, setSelectedRole] = useState('');
  const isDeleteDisabled = disabled ? true : !selectedRole.length;
  const handleRemoveChildRoles = () => {
    setForm({
      ...form,
      children: form.children.filter((child) => child.role !== selectedRole),
    });
  };
  const handleRowClick = (row, setActiveRow) => {
    setActiveRow(row.id);
    setSelectedRole(row.role);
  };
  const handleExpandChildrenRoles = () => {};

  return (
    <GroupRoleModelingBox
      roles={form.children}
      selectedRole={form}
      onAddChildRole={handleAddChildRole}
      onRemoveChildRoles={handleRemoveChildRoles}
      handleRowClick={handleRowClick}
      handleExpandChildrenRoles={handleExpandChildrenRoles}
      isAddDisabled={disabled}
      isDeleteDisabled={isDeleteDisabled}
    />
  );
};

export default GroupRoleView;

GroupRoleView.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool
};
