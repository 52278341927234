import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    setResultsOpen as setResultsOpenAction,
    setReportTypeWithColumns,
    setReportLevelWithColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
    fetchReportColumns,
    fetchReportResults,
    exportReport
} from "../../../reducers/reports/userLevelSlice";

import ReportResults from "../report-results";
import useReportReduxTable from "../../common/hooks/useReportReduxTable";


const UserLevelReportResults = () => {
    const dispatch = useDispatch();
    const reportLevelMode = useSelector(state => state.reports.userLevel.reportLevelMode)
    const isReportCalculated = useSelector(state => state.reports.userLevel.isReportCalculated)

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        reportLevel, setReportLevel,
        reportType, setReportType,
        open, setOpen
    } = useReportReduxTable(
        (state) => state.reports.userLevel.rows, fetchReportResults,
        (state) => state.reports.userLevel.columns, fetchReportColumns,
        (state) => state.reports.userLevel.selectedIds, setSelectedIdsAction,
        (state) => state.reports.userLevel.searchString, setSearchStringAction,
        (state) => state.reports.userLevel.pageNumber, setPageNumberAction,
        (state) => state.reports.userLevel.limitNumber, setLimitNumberAction,
        (state) => state.reports.userLevel.sortData, setSortDataAction,
        (state) => state.reports.userLevel.filterData, setFilterDataAction,
        (state) => state.reports.userLevel.error, setErrorAction,
        (state) => state.reports.userLevel.total,
        (state) => state.reports.userLevel.busy,
        (state) => state.reports.userLevel.reportLevel, setReportLevelWithColumns,
        (state) => state.reports.userLevel.reportType, setReportTypeWithColumns,
        (state) => state.reports.userLevel.resultsOpen, setResultsOpenAction
    );

    const handleExportClick = () => {
        dispatch(exportReport());
    };

    useEffect(()=>{
        setOpen(false);
    }, [reportLevelMode])

    return (
        <ReportResults
            open={open}
            setOpen={setOpen}
            reportLevel={reportLevel}
            setReportLevel={setReportLevel}
            reportType={reportType}
            setReportType={setReportType}
            rows={rows}
            columns={columns}
            total={total}
            busy={busy}
            selectedIds={selectedIds}
            searchString={searchString}
            setSearchString={setSearchString}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            filterData={filterData}
            setFilterData={setFilterData}
            sortData={sortData}
            setSortData={setSortData}
            onExportClick={handleExportClick}
            filterId="user-report"
            reportLevelMode={reportLevelMode}
            isReportCalculated={isReportCalculated}
        />
    );
};

export default UserLevelReportResults;
