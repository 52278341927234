import React, { useMemo, useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';

import { Box } from "@mui/material";

import { REPORT_TYPES, REPORT_LEVELS } from "../../../utils/sodReports.js";
import useFilters from "../../common/hooks/useFilters.js";

import CommonTable from "../../common/table";   
import { getCellClassNameInner } from "../../common/table";
import { IconButton } from "../../common/buttons";
import { ArrowDownIcon } from "../../common/icons";

import "./style.less";

const getCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    if (row["err_status"] === "in_sap") {
        className += " table-cell-in-sap";
    }

    if (row["err_status"] === "in_sod") {
        className += " table-cell-in-sod";
    }

    return className;
};

const ScrollButtons = ({ scrollTop, handleScrollToPosition }) => {
    return (
        <Box
            className="scroll-buttons"
            sx={{
                position: "absolute",
                top: `${scrollTop}px`,
                left: "0",
                zIndex: 1,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            }}
        >
            <IconButton
                sx={{ backgroundColor: "#E7E7E7" }}
                onClick={() => handleScrollToPosition(0)}
            >
                <ArrowDownIcon sx={{ transform: "rotate(90deg)", color: "white" }} />
            </IconButton>
            <IconButton
                sx={{ backgroundColor: "#E7E7E7" }}
                onClick={() => handleScrollToPosition(500)}
            >
                <ArrowDownIcon sx={{ transform: "rotate(270deg)", color: "white" }} />
            </IconButton>
        </Box>
    );
};

const ReportResultsTable = ({
    reportType, setReportType,
    rows, columns,
    total, selectedIds,
    searchString, setSearchString,
    pageNumber, setPageNumber,
    limitNumber, setLimitNumber,
    filterData, setFilterData,
    sortData, setSortData, reportLevel, filterId,
    conflictLevel, activeTabId
}) => {
    const wrapperRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false)

    useEffect(() => {
        const tableElement = document.querySelector(".report-results-table .table-container");
        if (tableElement) {
            setHasHorizontalScroll(tableElement.scrollWidth > tableElement.clientWidth && rows.length > 10);
        }
    }, [columns, rows]);

    const handleScrollToPosition = (position) => {
        const tableElement = document.querySelector(".report-results-table .table-container");
        if (tableElement) {
            tableElement.scrollTo({
                left: position,
                behavior: "smooth",
            });
        }

    };


    useEffect(() => {
        let timeoutId;

        const handleScroll = () => {
            if (wrapperRef.current) {
                const wrapperOffsetTop = wrapperRef.current.getBoundingClientRect().top;
                const scrollY = window.scrollY || document.documentElement.scrollTop;
                const calculatedScrollTop = Math.max(450, scrollY - wrapperOffsetTop);

                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    setScrollTop(calculatedScrollTop / 2);
                }, 500);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            clearTimeout(timeoutId);
        };
    }, []);

    const toolbarActions = useMemo(() => {
        if (activeTabId === REPORT_LEVELS.CRITICAL_OBJECTS) {
            return [];
        }
    
        return [
            {
                id: "select",
                type: "select",
                position: "end",
                options: [
                    { value: REPORT_TYPES.FINAL, title: "Итоговый отчет" },
                    { value: REPORT_TYPES.DETAILED, title: "Подробная информация" },
                ],
                value: reportType,
                callback: setReportType,
            },
        ];
    }, [activeTabId, reportType, setReportType]);

    const getFilters = useFilters(filterId, { report_level: reportLevel, report_type: reportType, conflict_level: conflictLevel });

    return (
        <Box className="report-results-table-wrapper" sx={{ position: "relative", overflow: "auto" }} ref={wrapperRef}>
            {hasHorizontalScroll &&
                <ScrollButtons scrollTop={scrollTop} handleScrollToPosition={handleScrollToPosition} />
            }
            <CommonTable
                className="report-results-table"
                toolbarActions={toolbarActions}
                rows={rows}
                columns={columns}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                showFiltersActions={false}
                searchString={searchString}
                setSearchString={setSearchString}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                selectedIds={selectedIds}
                getFilters={getFilters}
                getCellClassName={getCellClassName}
                handleFilterBeforeApply={(filter) => {
                    if (filter.field === "risk_level_text") {
                        return {
                            ...filter,
                            field: "risk_level_id"
                        }
                    }

                    return filter
                }}

            />
        </Box>
    );
};

ReportResultsTable.propTypes = {
    reportType: PropTypes.string,
    setReportType: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
    selectedIds: PropTypes.arrayOf(PropTypes.number),
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    pageNumber: PropTypes.number,
    limitNumber: PropTypes.number,
    setPageNumber: PropTypes.func,
    setLimitNumber: PropTypes.func,
    filterData: PropTypes.arrayOf(PropTypes.object),
    setFilterData: PropTypes.func,
    sortData: PropTypes.arrayOf(PropTypes.object),
    setSortData: PropTypes.func,
    reportLevel: PropTypes.string,
    filterId: PropTypes.string,
    conflictLevel: PropTypes.string, 
    activeTabId:PropTypes.string
};

ScrollButtons.propTypes = {
    scrollTop: PropTypes.number,
    handleScrollToPosition: PropTypes.func
}

export default ReportResultsTable;
