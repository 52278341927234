import React, { useId } from "react";
import PropTypes from "prop-types";

import DropdownSearchInput from "./dropdown-search-input.jsx";
import InputWrapper from "./input-wrapper.jsx";
import TagsList from "./tags-list.jsx";
import TagsListDetailed from "./tag-list-detailed.jsx";

const defaultGetListItemText = (item) => `${item.id} ${item.description}`;
const defaultGetListItemKey = (item) => item.id;
const defaultGetTagText = (key) => key;

const DropdownSearchHelpInput = ({
    className = "",
    fullWidth = true,
    shrink = true,
    label,
    ids,
    setIds,
    disabled,
    getData,
    dataKey = "rows",
    getTagText = defaultGetTagText,
    getListItemText = defaultGetListItemText,
    getListItemKey = defaultGetListItemKey,
    error = false,
    errorMessage,
    warning = false,
    warningMessage,
    showFormHelpers = true,
    showInnerHelpers = false,
    visibleRowCount,
    icon,
    disableMultiple = false,
}) => {
    const id = useId();

    const handleSelectKey = (key, checked, item) => {

        if (checked) {
            if (disableMultiple) {
              setIds([key], item);
            } else {
                setIds([...ids, key], item);

            }
        } else {
            setIds(ids.filter(id => id !== key), item);
        }

    };

    const handleDeleteKey = (key) => {
        if (disabled) return;
        handleSelectKey(key, false);
    };


    //if (disabled && ids.length === 0) return null;

    return (
        <InputWrapper
            id={id}
            className={className}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
            showFormHelpers={showFormHelpers}
            InputComponent={
                <DropdownSearchInput
                    id={id}
                    selectedKeys={ids}
                    onSelectKey={handleSelectKey}
                    getItemKey={getListItemKey}
                    getItemText={getListItemText}
                    dataKey={dataKey}
                    getData={getData}
                    fullWidth={fullWidth}
                    error={error}
                    errorMessage={errorMessage}
                    warning={warning}
                    warningMessage={warningMessage}
                    showInnerHelpers={showInnerHelpers}
                    visibleRowCount={visibleRowCount}
                    icon={icon}
                    disabled={disabled}
                />
            }
            EndComponent={
                <TagsList
                    ids={ids}
                    onDelete={handleDeleteKey}
                    getTagText={getTagText}
                />
            }
        />
    );
};


const DropdownSearchHelpTagInput = ({
    className = "",
    fullWidth = true,
    shrink = true,
    label,
    tags,
    setTags,
    disabled,
    getData,
    dataKey = "rows",
    getTagText = (item) => item.text ? `${item.key}: ${item.text}` : `${item.key}`,
    getListItemText = (item) => `${item.key} ${item.text}`,
    getListItemKey = (item) => item.key,
    error = false,
    errorMessage,
    warning = false,
    warningMessage,
    showFormHelpers = true,
    showInnerHelpers = false,
    visibleRowCount,
    onTagClick = () => { },
    disableMultiple = false,
    icon
}) => {
    const id = useId();

    const handleSelectKey = (key, checked, item) => {
        if (checked) {
            if (disableMultiple) {
                setTags([item]);
            } else {
                setTags([...tags, item]);
            } 
        } else {
            setTags(tags.filter(item => item.key !== key));
        }
    };

    const handleDeleteKey = (key) => {
        if (disabled) return;
        handleSelectKey(key, false);
    };


    //if (disabled && tags.length === 0) return null;

    return (
        <InputWrapper
            id={id}
            className={className}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
            showFormHelpers={showFormHelpers}
            InputComponent={
                <DropdownSearchInput
                    id={id}
                    selectedKeys={tags.map(tag => tag.key)}
                    onSelectKey={handleSelectKey}
                    getItemKey={getListItemKey}
                    getItemText={getListItemText}
                    dataKey={dataKey}
                    getData={getData}
                    fullWidth={fullWidth}
                    error={error}
                    errorMessage={errorMessage}
                    warning={warning}
                    warningMessage={warningMessage}
                    showInnerHelpers={showInnerHelpers}
                    visibleRowCount={visibleRowCount}
                    icon={icon}
                    disabled={disabled}
                />
            }
            EndComponent={
                <TagsListDetailed
                    getTagText={getTagText}
                    tags={tags}
                    onClick={onTagClick}
                    onDelete={handleDeleteKey}
                    selectedTagKey={null}
                />
            }
        />
    );
};

DropdownSearchHelpInput.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    disabled: PropTypes.bool,
    getData: PropTypes.func,
    dataKey: PropTypes.string,
    label: PropTypes.any,
    getTagText: PropTypes.func,
    getListItemText: PropTypes.func,
    getListItemKey: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    showInnerHelpers: PropTypes.bool,
    visibleRowCount: PropTypes.number,
    icon: PropTypes.node, 
    disableMultiple: PropTypes.bool
};


DropdownSearchHelpTagInput.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string
    })),
    setTags: PropTypes.func,
    disabled: PropTypes.bool,
    getData: PropTypes.func,
    dataKey: PropTypes.string,
    label: PropTypes.any,
    getTagText: PropTypes.func,
    getListItemText: PropTypes.func,
    getListItemKey: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    showInnerHelpers: PropTypes.bool,
    visibleRowCount: PropTypes.number,
    icon: PropTypes.node,
    onTagClick: PropTypes.func,
    disableMultiple: PropTypes.bool
};

export default DropdownSearchHelpInput;
export {
    DropdownSearchHelpTagInput
}