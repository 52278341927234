import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { systemsService } from "../../../../services/systems-service";

import { SearchHelpInput, TextInput, SelectInput } from "../../../common/form-controls";
import { MenuItem } from "../../../common/menu";
import SearchHelpDialog from "../../../common/search-helps";
import { ADD_MODE, EntityFormSubtitle, READ_MODE } from "../../../common/entity-form";

import { getSystemsSearchHelp } from "../../../../utils/searchHelps";
import CustomRoleFormPageViews from "./custom-role-form-page-views.jsx";



const RoleIdInput = ({ id, setId, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            label={intl.formatMessage({ id: "custom-role-form-page-role.input-role-id" })}
            value={id}
            onChange={(event) => setId(event.target.value)}
            disabled={disabled}
            error={error?.value === id}
            errorMessage={error?.message}
        />
    );
};

const RoleDescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            multiline
            rows={3}
            label={intl.formatMessage({ id: "custom-role-form-page-role.input-description" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const SystemInput = ({ systemId, setSystemId, disabled, error }) => {
    const intl = useIntl();

    const title = useMemo(
        () => intl.formatMessage({ id: "custom-role-form-page-role.input-system" }),
        [intl]
    );

    const columns = useMemo(
        () => [
            { id: "key", title: intl.formatMessage({ id: "search-help.columns.system" }) },
            { id: "text", title: intl.formatMessage({ id: "search-help.columns.description" }) }
        ],
        [intl]
    );

    const [open, setOpen] = useState(false);

    return (
        <>
            <SearchHelpInput
                label={title}
                value={systemId}
                error={error ? systemId === error.value : false}
                errorMessage={error && error.message}
                disabled={disabled}
                onChange={(event) => setSystemId(event.target.value)}
                onOpenSearchHelp={() => { setOpen(true) }}
            />

            <SearchHelpDialog
                defaultSearchString={systemId}
                title={title}
                columns={columns}
                open={open}
                readRows={getSystemsSearchHelp}
                onSubmit={(row) => {
                    setSystemId(row.key);
                    setOpen(false);
                }}
                onCancel={() => {
                    setOpen(false)
                }}
                selectOneRow={true}
            />
        </>
    );
};

const IsRoleComplexInput = ({ types, type, setIsComplex, disabled }) => {
    const intl = useIntl();

    return (
        <SelectInput
            label={intl.formatMessage({ id: "custom-role-form-page-role.role-type-input.label" })}
            value={type}
            onChange={(event) => setIsComplex(event.target.value)}
            disabled={disabled}
        >
            {types.map(item => (
                <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.text}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const types = [{
    id: false,
    text: <FormattedMessage id="custom-role-form-page-role.role-type-input-single" />
}, {
    id: true,
    text: <FormattedMessage id="custom-role-form-page-role.role-type-input-group" />
}]

const CustomRoleFormPageRole = ({
    form,
    mode,
    setForm,
    validationErrors,
    setValidationErrors,
    setBusy
}) => {
    const setId = value => setForm({ ...form, id: value });
    const setDescription = value => setForm({ ...form, description: value });
    const setIsComplex = value => setForm({ ...form, isComplex: value })



    const setSystemId = async (value) => {
        let systemError;
        let system;

        if (value) {
            try {
                system = await systemsService.getSystems(value);
            } catch (e) {
                systemError = e;
            }
        }

        const newSystemId = system?.id ?? value;

        setForm(form => {
            return {
                ...form,
                systemId: newSystemId,
            }
        });

        setValidationErrors(errors => {
            const newErrors = { ...errors };

            if (systemError) {
                newErrors.system_id = {
                    value: newSystemId,
                    message: systemError.detail ?? systemError.title
                };
            } else {
                delete newErrors.system_id;
            }

            return newErrors;
        });
    };

    const formDisabled = mode === READ_MODE;
    const idDisabled = mode !== ADD_MODE;
    const systemDisabled = mode !== ADD_MODE;
    const typeDisabled = mode !== ADD_MODE;
    const roleDisabled = mode === READ_MODE;

    return (
        <>
            <EntityFormSubtitle className="custom-role-form-page-custom-role-subtitle">
                <FormattedMessage id="function-form-page-function.title" />
            </EntityFormSubtitle>

            <Box className="custom-role-form-page-custom-role-inputs">
                <RoleIdInput
                    id={form.id}
                    setId={setId}
                    disabled={idDisabled}
                    error={validationErrors && validationErrors["role"]}
                />

                <RoleDescriptionInput
                    description={form.description}
                    setDescription={setDescription}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["description"]}
                />

                <SystemInput
                    systemId={form.systemId}
                    setSystemId={setSystemId}
                    disabled={systemDisabled}
                    error={validationErrors && validationErrors["system_id"]}
                />
                <IsRoleComplexInput
                    types={types}
                    type={form.isComplex}
                    setIsComplex={setIsComplex}
                    disabled={typeDisabled}
                    error={validationErrors && validationErrors["is_complex_role"]}
                />
            </Box>
            <CustomRoleFormPageViews 
                disabled={roleDisabled} 
                form={form} 
                setForm={setForm}
                setBusy={setBusy}></CustomRoleFormPageViews>
        </>
    )
}

CustomRoleFormPageRole.propTypes = {
    form: PropTypes.object,
    mode: PropTypes.string,
    setForm: PropTypes.func,
    setBusy: PropTypes.func,
    validationErrors: PropTypes.object,
    setValidationErrors: PropTypes.func,
    errorScroll: PropTypes.object,
    setErrorScroll: PropTypes.func
};

RoleIdInput.propTypes = {
    id: PropTypes.string,
    setId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

RoleDescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

SystemInput.propTypes = {
    systems: PropTypes.arrayOf(PropTypes.object),
    systemId: PropTypes.string,
    setSystemId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

IsRoleComplexInput.propTypes = {
    types: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,
    setIsComplex: PropTypes.func,
    disabled: PropTypes.bool
};

export default CustomRoleFormPageRole;
