import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    setResultsOpen as setResultsOpenAction,
    setReportTypeWithColumns,
    setReportLevelWithColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
    fetchReportColumns,
    fetchReportResults,
    exportReport
} from "../../../reducers/reports/roleLevelSlice";

import useReportReduxTable from "../../common/hooks/useReportReduxTable";

import ReportResults from "../report-results";


const RoleLevelReportResults = () => {
    const dispatch = useDispatch();
    const reportLevelMode = useSelector(state => state.reports.roleLevel.reportLevelMode)
    const isReportCalculated = useSelector(state => state.reports.roleLevel.isReportCalculated)

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        reportLevel, setReportLevel,
        reportType, setReportType,
        open, setOpen
    } = useReportReduxTable(
        (state) => state.reports.roleLevel.rows, fetchReportResults,
        (state) => state.reports.roleLevel.columns, fetchReportColumns,
        (state) => state.reports.roleLevel.selectedIds, setSelectedIdsAction,
        (state) => state.reports.roleLevel.searchString, setSearchStringAction,
        (state) => state.reports.roleLevel.pageNumber, setPageNumberAction,
        (state) => state.reports.roleLevel.limitNumber, setLimitNumberAction,
        (state) => state.reports.roleLevel.sortData, setSortDataAction,
        (state) => state.reports.roleLevel.filterData, setFilterDataAction,
        (state) => state.reports.roleLevel.error, setErrorAction,
        (state) => state.reports.roleLevel.total,
        (state) => state.reports.roleLevel.busy,
        (state) => state.reports.roleLevel.reportLevel, setReportLevelWithColumns,
        (state) => state.reports.roleLevel.reportType, setReportTypeWithColumns,
        (state) => state.reports.roleLevel.resultsOpen, setResultsOpenAction
    );

    const handleExportClick = () => {
        dispatch(exportReport());
    };

    useEffect(() => {
        setOpen(false);
    }, [reportLevelMode])

    return (
        <ReportResults
            open={open}
            setOpen={setOpen}
            reportLevel={reportLevel}
            setReportLevel={setReportLevel}
            reportType={reportType}
            setReportType={setReportType}
            rows={rows}
            columns={columns}
            total={total}
            busy={busy}
            selectedIds={selectedIds}
            searchString={searchString}
            setSearchString={setSearchString}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            filterData={filterData}
            setFilterData={setFilterData}
            sortData={sortData}
            setSortData={setSortData}
            onExportClick={handleExportClick}
            filterId="role-report"
            reportLevelMode={reportLevelMode}
            isReportCalculated={isReportCalculated}
        />
    );
};

export default RoleLevelReportResults;