import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { getRolesSearchHelp } from "../../../../utils/searchHelps";

import { reportsService } from "../../../../services/reports-service";
import { integrationService } from "../../../../services/integration-service";

import useRiskLevels from "../../../common/hooks/useRiskLevels";
import { SelectInput, SearchHelpInput, TextInput } from "../../../common/form-controls";
import { DropdownSearchHelpTagInput } from "../../../common/form-controls/dropdown-search-help-input.jsx";
import SearchHelpDialog from "../../../common/search-helps";
import { ADD_MODE, READ_MODE, EntityFormSubtitle } from "../../../common/entity-form";
import { MenuItem } from "../../../common/menu";

import "./style.less";

const RoleNameInput = ({ systemId, name, setName, disabled, error }) => {
    const intl = useIntl();

    const title = useMemo(
        () => intl.formatMessage({ id: "search-help.choose-critical-role.title" }),
        [intl]
    );

    const columns = useMemo(
        () => [
            { id: "key", title: intl.formatMessage({ id: "search-help.columns.critical-role" }) },
            { id: "text", title: intl.formatMessage({ id: "search-help.columns.description" }) },
        ],
        [intl]
    );

    const handleChange = async (event) => {
        const value = event.target.value;

        if (!value || !systemId) {
            setName(value);
            return;
        }

        try {
            const role = await integrationService.getRoleDetailed(value, systemId);
            setName(role?.role ?? value);
        } catch (e) {
            console.error(e);
            setName(value);
        }
    };

    const [open, setOpen] = useState(false);

    return (
        <>
            <SearchHelpInput
                label={intl.formatMessage({ id: "role-form-page-role.input-role-name" })}
                value={name}
                error={error ? (name === error.value && error["type"] === "validation") : false}
                errorMessage={error && error.message}
                warning={error ? (name === error.value && error["type"] === "integration_warning") : false}
                warningMessage={error && error.message}
                disabled={disabled}
                onChange={handleChange}
                onOpenSearchHelp={() => { setOpen(true) }}
            />

            <SearchHelpDialog
                defaultSearchString={name}
                title={title}
                columns={columns}
                open={open}
                readRows={(params, signal) => getRolesSearchHelp(params, signal, systemId)}
                onSubmit={(row) => {
                    setName(row.key)
                    setOpen(false)
                }}
                onCancel={() => {
                    setOpen(false)
                }}
                selectOneRow={true}
            />
        </>
    );
};


const RoleDescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            multiline
            rows={3}
            label={intl.formatMessage({ id: "role-form-page-role.input-description" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const RoleSystemInput = ({ systems, systemId, setSystemId, disabled, error }) => {
    const intl = useIntl();

    return (
        <SelectInput
            label={intl.formatMessage({ id: "role-form-page-role.input-system" })}
            value={systemId}
            onChange={(event) => setSystemId(event.target.value)}
            error={error ? systemId === error.value : false}
            errorMessage={error && error.message}
            disabled={disabled}
        >
            {systems.map(item => (
                <MenuItem
                    value={item.id}
                    key={item.id}
                >
                    {item.id}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const RoleMatrixHeaderInput = ({ ids, setIds, disabled, error }) => {
    const getMatrices = useCallback((params, signal) => {
        return reportsService.getMatrixSearchHelp(params, signal);
    }, []);

    return (
        <DropdownSearchHelpTagInput
            tags={ids}
            setTags={setIds}
            disabled={disabled}
            getData={getMatrices}
            error={error}
            label={<FormattedMessage id="risk-form-page-risk.input-matrix-headers" />}
        />
    );
};

const RoleRiskLevelInput = ({ riskLevels, riskLevelId, setRiskLevelId, disabled, error }) => {
    const intl = useIntl();

    return (
        <SelectInput
            label={intl.formatMessage({ id: "risk-form-page-risk.input-risk-lvl" })}
            value={riskLevelId}
            error={error ? riskLevelId === error.value : false}
            errorMessage={error && error.message}
            onChange={(event) => setRiskLevelId(event.target.value)}
            disabled={disabled}
        >
            {riskLevels.map(item => (
                <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.description}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const RoleActiveInput = ({ statuses, status, setStatus, disabled }) => {
    const intl = useIntl();

    return (
        <SelectInput
            label={intl.formatMessage({ id: "role-form-page-role.input-status" })}
            value={status}
            onChange={(event) => setStatus(event.target.value)}
            disabled={disabled}
        >
            {statuses.map(item => (
                <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.text}
                </MenuItem>
            ))}
        </SelectInput>
    )
};

const statuses = [{
    id: true,
    text: <FormattedMessage id="critical-objects.input-status.active" />
}, {
    id: false,
    text: <FormattedMessage id="critical-objects.input-status.inactive" />
}]

const RoleFormPageRole = ({ form, setForm, mode, validationErrors, systems }) => {
    const setName = value => setForm({ ...form, name: value });
    const setDescription = value => setForm({ ...form, description: value });
    const setSystemId = value => setForm({ ...form, system: value });
    const setRiskLevelId = value => setForm({ ...form, riskLevel: value })
    const setActive = value => setForm({ ...form, active: value })
    const setMatrixHeaders = (matrices) => setForm({ ...form, matrices: [...matrices] })

    const riskLevels = useRiskLevels();

    const formDisabled = mode === READ_MODE;
    const idDisabled = mode !== ADD_MODE;

    return (
        <>
            <EntityFormSubtitle className="role-form-page-role-subtitle">
                <FormattedMessage id="role-form-page-role.title" />
            </EntityFormSubtitle>

            <Box className="role-form-page-role-inputs">
                <RoleSystemInput
                    systems={systems}
                    systemId={form.system}
                    setSystemId={setSystemId}
                    disabled={idDisabled}
                    error={validationErrors && validationErrors["system_id"]}
                />

                <RoleNameInput
                    systemId={form.system}
                    name={form.name}
                    setName={setName}
                    disabled={idDisabled || !form.system}
                    error={validationErrors && validationErrors["crit_role_name"]} />

                <RoleMatrixHeaderInput
                    ids={form.matrices}
                    setIds={setMatrixHeaders}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["matrix_set"]}
                />

                <RoleRiskLevelInput
                    riskLevels={riskLevels}
                    riskLevelId={form.riskLevel}
                    setRiskLevelId={setRiskLevelId}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["risk_level_id"]}
                />

                <RoleActiveInput
                    statuses={statuses}
                    status={form.active}
                    setStatus={setActive}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["active"]}
                />

                <RoleDescriptionInput
                    description={form.description}
                    setDescription={setDescription}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["description"]}
                />
            </Box>
        </>
    );
};

RoleNameInput.propTypes = {
    systemId: PropTypes.string,
    name: PropTypes.string,
    setName: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

RoleDescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

RoleSystemInput.propTypes = {
    systems: PropTypes.arrayOf(PropTypes.object),
    systemId: PropTypes.string,
    setSystemId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

RoleMatrixHeaderInput.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.string),
    setIds: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

RoleRiskLevelInput.propTypes = {
    riskLevels: PropTypes.arrayOf(PropTypes.object),
    riskLevelId: PropTypes.string,
    setRiskLevelId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

RoleActiveInput.propTypes = {
    statuses: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.string,
    setStatus: PropTypes.func,
    disabled: PropTypes.bool
};

RoleFormPageRole.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    mode: PropTypes.string,
    validationErrors: PropTypes.object,
    systems: PropTypes.arrayOf(PropTypes.object)
};

export default RoleFormPageRole;
