import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { usersService } from "../services/users-service";
import { reportsService } from "../services/reports-service";

import { setTokens, removeTokens, getRefreshToken } from "../utils/auth";
import { getActionTypeForVariantType, getReportVariantAction, getReportVariantRoute, REPORT_VARIANT_TYPES, USER_REPORT_ACTIONS_TYPES } from "../utils/sodReports";
import SodSocketService from "../services/sod-socket-service";

import {
    setFavoritesForVariant as setFavoritesForVariantUserReport,
    deleteVariant as deleteVariantUserReport
} from "./reports/userLevelSlice";

import {
    setFavoritesForVariant as setFavoritesForVariantRoleReport,
    deleteVariant as deleteVariantRoleReport
} from "./reports/roleLevelSlice";

import {
    setFavoritesForVariant as setFavoritesForVariantRoleModeling,
    deleteVariant as deleteVariantRoleModeling
} from "./reports-modeling/roleLevelSlice";

import {
    setFavoritesForVariant as setFavoritesForVariantUserModeling,
    deleteVariant as deleteVariantUserModeling
} from "./reports-modeling/userLevelSlice";
import { BUSY_TYPES } from "../utils/busy";

const initialState = {
    signedIn: !!getRefreshToken(),
    isSigningIn: false,
    failedToSignIn: false,
    user: null,
    error: null,
    busy: false,
    busyType: null,
    validationErrors: null,
    userAgreementText: "",
};

const updateReportsTabForFavorites = (state, action, variantType) => {
    const { variantName, inFavorites } = action.meta.arg;
    const variantRoute = getReportVariantRoute(variantName, variantType);
    const actionType = getActionTypeForVariantType(variantType);

    if (!inFavorites) {
        state.user.actions = state.user.actions.filter(userAction => (
            userAction.type !== actionType ||
            userAction.route !== variantRoute
        ));

        return;
    }

    const userAction = getReportVariantAction(variantName, variantRoute, actionType);
    state.user.actions.push(userAction);
};

const updateReportsTabForDeleteVariant = (state, action, variantType) => {
    const variantName = action.meta.arg;
    const variantRoute = getReportVariantRoute(variantName, variantType);
    const actionType = getActionTypeForVariantType(variantType);

    state.user.actions = state.user.actions.filter(userAction => (
        userAction.type !== actionType ||
        userAction.route !== variantRoute
    ));
};

const addFavoritesToActions = (user, variants) => {
    variants
        .filter(variant => variant.inFavorites)
        .forEach(variant => {
            const variantRoute = getReportVariantRoute(variant.variantName, variant.variantType);
            const actionType = getActionTypeForVariantType(variant.variantType);
            const action = getReportVariantAction(variant.variantName, variantRoute, actionType);
            user.actions.push(action)
        });
};

export const signIn = createAsyncThunk(
    "auth/signIn",
    async (args) => {
        try {
            const data = await usersService.signIn(args.username, args.password);
            setTokens(data.access, data.refresh);
        } catch (error) {
            removeTokens();
            throw error;
        }
    }
);

export const fetchUser = createAsyncThunk(
    "auth/fetchUser",
    async (_, { rejectWithValue }) => {
        let user;

        try {
            user = await usersService.getCurrentUser();
        } catch (error) {
            removeTokens();
            return rejectWithValue(error);
        }

        try {
            const sockerSerivce = SodSocketService.getInstance()

            await sockerSerivce.connect()
        } catch (error) {
            console.log(error)
        }

        const hasTabWithVariants = user.actions.some(action => (
            action.type === USER_REPORT_ACTIONS_TYPES.SOD_REPORTS ||
            action.type === USER_REPORT_ACTIONS_TYPES.REPORTS_MODELING
        ));

        if (!hasTabWithVariants) return user;

        try {
            const variants = await reportsService.getVariantsByUser(null, false);
            addFavoritesToActions(user, variants);
        } catch (error) {
            console.error(error);
        }

        return user;
    }
)

export const fetchUserAgreementText = createAsyncThunk(
    "auth/fetchUserAgreementText",
    async () => {
        const response = await usersService.getUserAgreementText();
        return response;
    }
);

export const acceptUserAgreement = createAsyncThunk(
    "auth/acceptUserAgreement",
    async () => {
        const response = await usersService.acceptUserAgreement();
        return response;
    }
);

export const changeUserPasswordInProfile = createAsyncThunk(
    "auth/changeUserPassword",
    async ({ newPassword }, { rejectWithValue }) => {
        let response;
        try {
            response = await usersService.changeUserPasswordInProfile(newPassword);
        } catch (messages) {
            return rejectWithValue(messages)
        }
        return response

    }
)

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        }
    },
    extraReducers: {
        [signIn.pending]: (state) => {
            state.isSigningIn = true;
            state.failedToSignIn = false;
            state.error = null
        },
        [signIn.fulfilled]: (state) => {
            state.isSigningIn = false;
            state.failedToSignIn = false;
            state.signedIn = true;
        },
        [signIn.rejected]: (state) => {
            state.isSigningIn = false;
            state.failedToSignIn = true;
            state.signedIn = false;
        },

        [fetchUser.fulfilled]: (state, action) => {
            state.isSigningIn = false;
            state.failedToSignIn = false;
            state.signedIn = true;
            state.user = action.payload;
            state.error = null
        },
        [fetchUser.rejected]: (state, action) => {
            state.isSigningIn = false;
            state.failedToSignIn = false;
            state.signedIn = false;

            const error = action && action.payload

            state.error = error
        },

        [setFavoritesForVariantUserReport.fulfilled]: (state, action) => {
            updateReportsTabForFavorites(
                state, action, REPORT_VARIANT_TYPES.USERS
            );
        },

        [setFavoritesForVariantRoleReport.fulfilled]: (state, action) => {
            updateReportsTabForFavorites(
                state, action, REPORT_VARIANT_TYPES.ROLES
            );
        },

        [setFavoritesForVariantRoleModeling.fulfilled]: (state, action) => {
            updateReportsTabForFavorites(
                state, action, REPORT_VARIANT_TYPES.ROLES_MODELING
            );
        },

        [setFavoritesForVariantUserModeling.fulfilled]: (state, action) => {
            updateReportsTabForFavorites(
                state, action, REPORT_VARIANT_TYPES.USERS_MODELING
            );
        },

        [deleteVariantUserReport.fulfilled]: (state, action) => {
            updateReportsTabForDeleteVariant(
                state, action, REPORT_VARIANT_TYPES.USERS
            );
        },

        [deleteVariantRoleReport.fulfilled]: (state, action) => {
            updateReportsTabForDeleteVariant(
                state, action, REPORT_VARIANT_TYPES.ROLES
            );
        },

        [deleteVariantRoleModeling.fulfilled]: (state, action) => {
            updateReportsTabForDeleteVariant(
                state, action, REPORT_VARIANT_TYPES.ROLES_MODELING
            );
        },

        [deleteVariantUserModeling.fulfilled]: (state, action) => {
            updateReportsTabForDeleteVariant(
                state, action, REPORT_VARIANT_TYPES.USERS_MODELING
            );
        },

        [changeUserPasswordInProfile.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE
        },
        [changeUserPasswordInProfile.fulfilled]: (state) => {
            state.busy = false;
            state.error = null;
        },
        [changeUserPasswordInProfile.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)) {
                state.validationErrors = error.errors;
            }
        },


        [fetchUserAgreementText.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchUserAgreementText.fulfilled]: (state, action) => {
            state.busy = false;
            state.userAgreementText = action.payload;
        },

        [fetchUserAgreementText.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },

        [acceptUserAgreement.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [acceptUserAgreement.fulfilled]: (state) => {
            state.busy = false;
        },

        [acceptUserAgreement.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },

    }
});

export const selectUser = (state) => state.auth.user;

export const selectUserAgreementText = (state) => state.auth.userAgreementText

export const selectUserScreenButton = createSelector(
    selectUser,
    (_, id) => id,
    (user, id) => user?.screenButtons?.find(button => button.id === id)
);

export const {
    setError, setValidationErrors
} = authSlice.actions;

export default authSlice.reducer;
