import downloadFile, { getFilenameFromResponse } from "../utils/downloadFile.js";
import { REPORT_TYPES, REPORT_VARIANT_TYPES } from "../utils/sodReports.js";

import SodService from "./sod-service.js";


class ReportsService extends SodService {
    async getMatrixSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/reports/search-help/matrix", config);

        const rows = data?.rows.map((matrixHeader) => this.parseMatrixHeader(matrixHeader));
        const total = data?.total;

        return { rows, total };
    }

    async getUsersSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);
    
        const data = await this.get("/sod/reports/search-help/users", config);

        const rows = data && data.rows.map(item => this.parseEmployee(item));
        const total = data && data.total;

        return { rows, total };
    }
    async getSystemsSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/reports/search-help/systems-extended", config);

        const rows = data && data.rows.map(item => this.parseSystemExtendedSearchHelp(item));
        const total = data && data.total;

        return { rows, total };
    }

    async getSystemsForSimulationSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/reports/simulation/search-help/systems", config);

        const rows = data && data.rows.map(item => ({
            key: item.system_id,
            isGroup: false,
            text: item.description
        }));
        
        const total = data && data.total;

        return { rows, total };
    }

    async getEmployeesForSimulationSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/reports/simulation/search-help/employees", config);

        const rows = data && data.rows.map(item => this.parseEmployee(item));
        const total = data && data.total;

        return { rows, total };
    }

    parseSystemExtendedSearchHelp(item){
        return {
            //id: nanoid(),
            key: item.key,
            isGroup: item.is_group,
            text: item.text
        }
    }

    async getRisksSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/reports/search-help/risks", config);

        const rows = data && data.rows.map(item => this.parseRisk(item));
        const total = data.total

        return { rows, total };
    }

    async getRiskLevelSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/reports/search-help/risk-levels", config);

        const rows = data && data.rows.map(item => this.parseRiskLevel(item));
        const total = data && data.total;

        return { rows, total };
    }

    async getRolesSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/reports/search-help/roles", config);

        const rows = data && data.rows.map(item => this.parseRole(item));
        const total = data && data.total;

        return { rows, total };
    }

    async getReportResults(endpoint, params) {
        const config = this.generateConfigForGetEntities(params);
        const data = await this.get(endpoint, config);

        const rows = data?.rows || [];
        const total = data?.total || 0;

        return { rows, total };
    }

    async getUserReportFilters(params, entityParams) {
        const paramsNew = {...params, ...entityParams}
        return this.getFilters("/sod/reports/user-level/filters", paramsNew);
    }

    async getRoleReportFilters(params, entityParams) {
        const paramsNew = {...params, ...entityParams}
        return this.getFilters("/sod/reports/role-level/filters", paramsNew);
    }

    async getRoleLevelReportResults(params) {
        return this.getReportResults("/sod/reports/role-level/result", params);
    }

    async calRoleLevelReport(form, params) {
        const config = this.generateConfigForGetEntities(params)
        const data = await this.post("/sod/reports/role-level/calc", form, config);
        return data;
    }

    async getUserLevelReportResults(params) {
        return this.getReportResults("/sod/reports/user-level/result", params);
    }

    async getUserLevelBusynessReportResults(params) {
        return this.getReportResults("/sod/reports/user-busyness-level/result", params);
    }

    async getUserLevelBusynessDetailedReportResults(params) {
        return this.getReportResults("/sod/reports/user-detailed-business-level/result", params);
    }

    async getUserLevelBusynessReportInfo() {
        return this.get("/sod/reports/user-busyness-level/info");
    }

    formatDate(date) {
    
        let month = '' + (date.getMonth() + 1)
        let day = '' + date.getDate()
        let year = date.getFullYear()
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('.');
    }

    async getUserBusynessReportFilters(params, entityParams) {

        if (params && params.field === "calc_date"){
            const result = [{
                key: "in_early",
                text: this.formatDate(new Date(entityParams.earlyDate))
            }, {
                key: "in_late",
                text: this.formatDate(new Date(entityParams.lateDate))
            }] 
            return {
                rows: result,
                total: result.length
            } 
        }

        const paramsNew = {...params, ...entityParams}
        return this.getFilters("/sod/reports/user-busyness-level/filters", paramsNew);
    }

    async getUserBusynessDetailedReportFilters(params, entityParams) {
        const paramsNew = {...params, ...entityParams}
        return this.getFilters("/sod/reports/user-detailed-business-level/filters", paramsNew);
    }

    async exportReport(endpoint, params) {
        let response;
        const currentInstance = this.getCurrentInstance(true);

        try {
            response = await currentInstance.get(endpoint, {
                params: {
                    ...params,
                    export: true
                },
                responseType: "blob"
            });
        } catch (error) {
            this.handleError(error);
        }

        const data = response.data || null;
        const filename = getFilenameFromResponse(response);

        downloadFile(data, filename);
    }

    exportUserLevelReport(params) {
        return this.exportReport("/sod/reports/user-level/result", params);
    }

    exportRoleLevelReport(params) {
        return this.exportReport("/sod/reports/role-level/result", params);
    }

    async calUserLevelReport(form, params) {
        const config = this.generateConfigForGetEntities(params)
        const data = await this.post("/sod/reports/user-level/calc", form, config);
        return data;
    }


    async calUserLevelBusynessReport() {
        const data = await this.post("/sod/reports/user-busyness-level/calc", {});
        return data;
    }

    async calUserLevelBusynessReportDelta(earlyDate, lateDate) {
        const data = await this.post("/sod/reports/user-business-level/delta-compare", {first_date: earlyDate, second_date: lateDate});
        return data;
    }


    async getUserLevelBusynessReportDeltaInfo(earlyDate, lateDate) {
        const params = {
            params: {earlyDate: earlyDate, lateDate: lateDate}
        }
        const data = await this.get("/sod/reports/user-busyness-level/delta/info", params);
        return data;
    }


    async scheduleUserLevelBusynessReport(scheduleItems) {
        const data = await this.post("/sod/reports/user-busyness-level/schedule", scheduleItems.map(item => ({
            period: item.period,
            hours: item.hours,
            minutes: item.minutes
        })));

        return data;
    }


    async getScheduleUserLevelBusynessReportItems() {
        const data = await this.get("/sod/reports/user-busyness-level/schedule");

        return data;
    }


    async calUserLevelBusynessDetailedReport(rows) {
        const data = await this.post("/sod/reports/user-detailed-business-level/calc", rows.map(row => ({
            employee: row.employee,
            system: row.systemId,
            risk: row.riskId
        })));
        return data;
    }

    async compareUserLevelReport(form) {
        const data = await this.post("/sod/reports/user-level/compare", form);
        return data;
    }
    
    async compareUserLevelBusynessReport(form) {
        const data = await this.post("/sod/reports/user-busyness-level/compare", form);
        return data;
    }

    async compareRoleLevelReport(form) {
        const data = await this.post("/sod/reports/role-level/compare", form);
        return data;
    }

    async saveVariant(form, params, isNew=true) {
        const config = this.generateConfigForGetEntities(params);

        const save = isNew ? this.post : this.put;

        const data = await save.call(this, "/sod/reports/variant", form, config);
        return this.parseVariant(data);
    }

    async setFavoritesForVariant(form) {
        await this.post("/sod/reports/variant/set-favorites", form);
    }

    async deleteVariant(params) {
        const config = this.generateConfigForGetEntities(params);
        const data = await this.delete("/sod/reports/variant", {}, config);
        return data
    }

    async getVariantsByUser(params, redirectWhenNotFound=true) {
        const config = this.generateConfigForGetEntities(params);
        const data = await this.get("/sod/reports/variants", config, true, redirectWhenNotFound);

        const variants = data?.map((variant) => this.parseVariant(variant));

        return variants;
    }

    async getReportColumns(endpoint, reportLevel, reportType, defaultColumns) {
        const config = this.generateConfigForGetEntities({
            default: defaultColumns
        });
        const data = await this.get(`${endpoint}/${reportType}/${reportLevel}`, config);
        const columns = data?.map((column) => this.parseReportColumn(column));
        return columns;
    }

    async getRoleLevelReportColumns(reportLevel, reportType, defaultColumns) {
        return this.getReportColumns("/sod/reports/user-role-column", reportLevel, reportType, defaultColumns);
    }

    async getUserLevelReportColumns(reportLevel, reportType, defaultColumns) {
        return this.getReportColumns("/sod/reports/user-column", reportLevel, reportType, defaultColumns);
    }

    async getUserLevelBusynessReportColumns() {
        return [
            {
                "id": "employee_id",
                "title": "Пользователь",
                "type": "string",
                "sortable": true,
                "filterable": true,
                "export_field": "employee_id",
                "active": true
            },
            {
                "id": "risk_id",
                "title": "Риск",
                "type": "string",
                "sortable": true,
                "filterable": true,
                "export_field": "risk_id",
                "active": true
            },
            {
                "id": "system_id",
                "title": "Система",
                "type": "string",
                "sortable": true,
                "filterable": true,
                "export_field": "system_id",
                "active": true
            },
        ]
        //return this.getReportColumns("/sod/reports/user-column", defaultColumns);
    }

    async saveReportColumns(endpoint, columns, reportLevel, reportType) {
        return this.post(
            `${endpoint}/${reportType}/${reportLevel}`,
            columns.map(column => this.parseReportColumnToServer(column))
        );
    }

    async saveRoleLevelReportColumns(columns, reportLevel, reportType) {
        return this.saveReportColumns("/sod/reports/user-role-column", columns, reportLevel, reportType);
    }

    async saveUserLevelReportColumns(columns, reportLevel, reportType) {
        return this.saveReportColumns("/sod/reports/user-column", columns, reportLevel, reportType);
    }

    parseVariant(variant) {
        const variantData = {
            matrixHeaders: variant.variant_data.matrix_headers,
            risks: variant.variant_data.risks,
            systems: variant.variant_data.systems,
            riskLevels: variant.variant_data.risk_levels,
            groups: variant.variant_data.groups
        };

        switch (variant.variant_type) {
            case REPORT_VARIANT_TYPES.ROLES:
                variantData.roles = variant.variant_data.roles;
                variantData.reportLevelMode = variant.variant_data.report_level_mode
                break
            case REPORT_VARIANT_TYPES.ROLES_MODELING:
                variantData.roles = variant.variant_data.roles;
                break;
            
            case REPORT_VARIANT_TYPES.USERS:
                variantData.usernames = variant.variant_data.usernames;
                variantData.reportLevelMode = variant.variant_data.report_level_mode
                break
            case REPORT_VARIANT_TYPES.USERS_MODELING:
                variantData.usernames = variant.variant_data.usernames;
                break;
        }

        return {
            variantName: variant.variant_name,
            variantData: variantData,
            inFavorites: variant.in_favorites,
            variantType: variant.variant_type,
            variantOwner: variant.variant_owner
        }
    }

    parseReportLine(item, reportType){
        switch(reportType){
            case REPORT_TYPES.FINAL:
                return {
                    userId: item.username,
                    riskId: item.risk_id,
                    permission: item.permission
                }
            case REPORT_TYPES.DETAILED:
                return {
                    userId: item.username,
                    riskId: item.risk_id,
                    ruleId: item.rule_id,
                    riskLevel: item.risk_level_id,
                    riskLevelText: item.risk_level_text,
                    functionId: item.function_id,
                    system: item.system_id,
                    operation: item.action,
                    updDate: item.last_update_date,
                    permission: item.permission,
                    field: item.field
                }
        }
    }

    parseMatrixHeader(matrixHeader) {
        return {
            key: matrixHeader.matrix_id,
            text: matrixHeader.description,
        };
    }

    parseEmployee(employee) {
        return {
            id: employee.employee,
            description: employee.fullname
        }
    }

    parseSystem(system) {
        return {
            id: system.system_id,
            description: system.description
        }
    }

    parseRisk(risk) {
        return {
            key: risk.risk_id,
            text: risk.description
        }
    }

    parseRiskLevel(riskLevel) {
        return {
            key: riskLevel.risk_level_id,
            text: riskLevel.description
        }
    }

    parseRole(item) {
        return {
            id: item.role,
            description: item.description
        }
    }

    parseReportColumn(column) {
        const parsedColumn = this.parseColumn(column);

        return {
            ...parsedColumn,
            exportField: column.export_field,
            active: column.active
        }
    }

    parseReportColumnToServer(column) {
        return {
            column_id: column.id,
            title: column.title,
            type: column.type,
            sortable: column.sortable,
            filterable: column.filterable,
            export_field: column.exportField,
            active: column.active
        }
    }
}

const reportsService = new ReportsService();

export { reportsService };
export default ReportsService;
