import ReportParameters from "./report-parameters.jsx";
import RiskLevelInput from "./risk-level-input.jsx";
import RiskIdsInput from "./risk-ids-input.jsx";
import MatrixIdsInput from "./matrix-ids-input.jsx";
import SystemIdsInput from "./system-ids-input.jsx";
import RolesInput from "./roles-input.jsx";
import UsersInput from "./users-input.jsx";
import OnlineSyncCheckbox from "./online-sync-checkbox.jsx";
import CriticalObjectsCheckbox from "./critical-objects-checkbox.jsx"

export { RiskLevelInput, RiskIdsInput, MatrixIdsInput };
export { SystemIdsInput, RolesInput, UsersInput };
export { OnlineSyncCheckbox, CriticalObjectsCheckbox };
export default ReportParameters;
