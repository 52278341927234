import SodService from "./sod-service";

class SystemsService extends SodService {
    async getSystems(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/systems/list", config);

        const systems = data && data.rows.map(item => this.parseSystem(item));
        const total = data && data.total;

        return { systems, total };
    }

    async getSapSystems(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/systems/list/sap", config);

        const systems = data && data.rows.map(item => this.parseSystem(item));
        const total = data && data.total;

        return { systems, total };
    }

    async getSystemFilters(params, signal) {
        return this.getFilters("/core/systems/filters", params, signal);
    }

    async getSystemsExtended(params, signal) {
        return this.getFilters("/core/systems/systems-extended/list", params, signal);
    }

    async getSystemExtendedDetailed(objectId, signal) {
        return this.get(`/core/systems/system-extended/detailed/${objectId}`, signal);
    }

    async getSystemColumns() {
        return await this.getColumns("/core/systems/columns");
    }
    
    async getSystemChangeHistory(key) {
        const data = await this.get(`/core/systems/detailed/change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    async getSystemDetailed(systemId) {
        const data = await this.get(`/core/systems/detailed/${encodeURIComponent(systemId)}`);

        return this.parseSystem(data);
    }

    async getSystemsSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/systems/list", config);

        const systems = data && data.rows.map(item => this.parseSystemSearchHelp(item));
        const total = data && data.total;

        return { rows: systems, total }
    }

    async deleteSystem(id) {
        return this.delete(`/core/systems/detailed/${encodeURIComponent(id)}`);
    }

    async editSystem(system) {
        const config = {
            params: {
                integration_type: system.authTypeId
            }
        }
        const data = await this.put(`/core/systems/detailed/${encodeURIComponent(system.id)}`, this.parseSystemToServer(system), config);

        return this.parseSystem(data);
    }

    async changeSystemPassword(systemId, password) {
        const data = await this.post("/core/systems/change-password", {
            system_id: systemId,
            password
        });

        return data;
    }

    async createSystem(system) {
        const config = {
            params: {
                integration_type: system.authTypeId
            }
        }
        const data = await this.post(`/core/systems/detailed/${encodeURIComponent(system.id)}`, this.parseSystemToServer(system), config);

        return this.parseSystem(data);
    }

    async testConnectionWithSAP(system) {
        const config = {
            params: {
                integration_type: system.authTypeId,
                edit_mode: system.editFlag
            }
        }

        await this.post('/core/integration/check-connection', this.parseSystemToServer(system) , config);
        return;
    }

    parseSystemSearchHelp(item) {
        return {
            key: item.system_id,
            text: item.description
        }
    }

    parseSystem(item) {
        return {
            id: item.system_id,
            description: item.description,
            login: item.login,
            password: item.password,
            host: item.host,
            instanceNumber: item.instance_number,
            mandt: item.mandt,
            sapRouter: item.sap_router,
            sapSystemId: item.sap_system_id,
            authTypeId: item.integration_type,
            url: item.url,
            systemTypeId: item.system_type,
        };
    }

    parseSystemToServer(system) {
        return {
            system_id: system.id,
            description: system.description,
            login: system.login,
            password: system.password,
            host: system.host,
            mandt: system.mandt,
            instance_number: system.instanceNumber,
            sap_router: system.sapRouter,
            sap_system_id: system.sapSystemId,
            integration_type: system.authTypeId,
            url: system.url,
            system_type: system.systemTypeId
        };
    }
}

const systemsService = new SystemsService();

export { systemsService };
export default SystemsService;